import React from "react";

export const About = () => {
    return (
        <>
            <h1 className=" text-2xl md:text-4xl font-bold text-red-800 my-6 bg-gray-100 p-3 md:mx-16 rounded-2xl">FAQ
                ABOUT BETTING IN INDIA </h1>
            <div className="collapse collapse-arrow  mb-2 lg:mx-16 text-start lg:w-[70rem] bg-base-200">
                <input type="radio" name="my-accordion-2" defaultChecked/>
                <div className="collapse-title md:text-xl font-medium">
                    Is GamesWalla safe to use in India?
                </div>
                <div className="collapse-content">
                    <p>Yes, It is completely safe to use ExchangeMarket in India. Transactions are completely
                        transparent and there are no middlemen. Everything from registration to withdrawal is authorized
                        and thoroughly checked with proper supervision. </p>
                </div>
            </div>
            <div className="collapse collapse-arrow  mb-2 lg:mx-16 text-start lg:w-[70rem]  bg-base-200">
                <input type="radio" name="my-accordion-2"/>
                <div className="collapse-title md:text-xl font-medium">
                    Are betting exchanges legal in India?
                </div>
                <div className="collapse-content">
                    <p>The country’s laws are not so strict when it comes to betting. It all depends on licenses and
                        authorizations. Therefore, as long as the betting exchange has a license, they are allowed to be
                        open by Indian law and the operation can be legal.</p>
                </div>
            </div>
            <div className="collapse collapse-arrow  mb-2 lg:mx-16 text-start lg:w-[70rem]   bg-base-200">
                <input type="radio" name="my-accordion-2"/>
                <div className="collapse-title md:text-xl font-medium">
                    Can GamesWalla be trusted or not?
                </div>
                <div className="collapse-content">
                    <p>Since GamesWalla has a valid license and authorization under the Curacao Gaming Authority, it is
                        legally operational in India. The Indian law wouldn’t allow a sportsbook to function in India if
                        it doesn’t hold a valid license. Therefore, you can completely trust GamesWalla and put your
                        faith in it.</p>
                </div>
            </div>
            <div className="collapse collapse-arrow  mb-2 lg:mx-16 text-start lg:w-[70rem]  bg-base-200">
                <input type="radio" name="my-accordion-2"/>
                <div className="collapse-title md:text-xl font-medium">
                    Is GamesWalla real or fake?
                </div>
                <div className="collapse-content">
                    <p>GamesWalla is completely real. You can surf through it and find the games and sports you need.
                        You can also chat with the support team through live chat method and clear your
                        misconceptions.</p>
                </div>
            </div>
            <div className="collapse collapse-arrow  mb-2 lg:mx-16 text-start lg:w-[70rem]   bg-base-200">
                <input type="radio" name="my-accordion-2"/>
                <div className="collapse-title md:text-xl font-medium">
                    Which is the most trusted betting site in India?
                </div>
                <div className="collapse-content">
                    <p>Currently, GamesWalla is the most trusted betting site in India. The safety and security net it
                        provides to its customers cannot be beat. Various layers of security have been put in place to
                        protect the valuable data of customers. This is so that they can place bets peacefully.</p>
                </div>
            </div>
            <div className="collapse collapse-arrow  mb-2 lg:mx-16 text-start lg:w-[70rem]   bg-base-200">
                <input type="radio" name="my-accordion-2"/>
                <div className="collapse-title md:text-xl font-medium">
                    Which is the largest betting exchange in India?
                </div>
                <div className="collapse-content">
                    <p>According to Google’s latest reports,GamesWalla is the largest betting exchange in India. It has
                        a huge game library that includes a huge amount of sports and casino games. The amount of
                        betting lines and odds that GamesWalla offers is unparalleled. Therefore, all these factors make
                       GamesWalla the largest betting exchange in India.

                    </p>
                </div>
            </div>
            <div className="collapse collapse-arrow  mb-2 lg:mx-16 text-start lg:w-[70rem]   bg-base-200">
                <input type="radio" name="my-accordion-2"/>
                <div className="collapse-title md:text-xl font-medium">
                    Is GamesWalla banned in India?
                </div>
                <div className="collapse-content">
                    <p>No,GamesWalla is not banned in India. It has a valid license and is authorized under the Curacao
                        Gaming Authority. Therefore, there is no reason to ban it in India. You can simply visit the
                        official website and check it out yourself.

                    </p>
                </div>
            </div>
            <div className="collapse collapse-arrow  mb-2 lg:mx-16 text-start lg:w-[70rem]   bg-base-200">
                <input type="radio" name="my-accordion-2"/>
                <div className="collapse-title md:text-xl font-medium">
                    How do I withdraw money from GamesWalla?
                </div>
                <div className="collapse-content">
                    <p>There are various payment methods available in GamesWalla for withdrawals. UPI, E-wallet,
                        PhonePe, GPay and Paytm are some of the available payment modes in GamesWalla. Transactions
                        happen instantly.

                    </p>
                </div>
            </div>
            <div className="collapse collapse-arrow  mb-2 lg:mx-16 text-start lg:w-[70rem]   bg-base-200">
                <input type="radio" name="my-accordion-2"/>
                <div className="collapse-title md:text-xl font-medium">
                    How long does it take to withdraw your winnings from GamesWalla?
                </div>
                <div className="collapse-content">
                    <p>
                        Your winnings are withdrawn almost instantly. However, mostly it depends on the payment method you choose. Some methods provide instant withdrawals while others may take a day or two to process.
                    </p>
                </div>
            </div>
            <div className="collapse collapse-arrow  mb-2 lg:mx-16 text-start lg:w-[70rem]   bg-base-200">
                <input type="radio" name="my-accordion-2"/>
                <div className="collapse-title md:text-xl font-medium">
                    How do I contact GamesWalla customer service number?
                </div>
                <div className="collapse-content">
                    <p>You can contact GamesWalla customer service by dialling this number: +44 7537130005 and +91 8200452395. A representative will pick up the call immediately and resolve your issues as soon as possible.
                    </p>
                </div>
            </div>
        </>
    )
}
