import WhatsApp from '../Images/whatsapp.png'
export const Whatsapp = () => {
    return (
        <>
            <div className="fixed bottom-4 right-4 min-[1200px]:right-32">
                <a href='https://wa.link/8l2fxm'> <img className="mt-4 w-16" src={WhatsApp} alt="whatsapp"/>
                </a>
            </div>
        </>
    )
}
