import {FeedbackCard} from "./Card/FeedbackCard";

export const Feedback = () => {
    return (
        <div className='mx-8 md:mx-16 my-8'>
            <h1 className=' text-2xl md:text-4xl font-bold text-gray-900 my-6 bg-red-500 p-3 rounded-2xl'>
                Our Valuable Customer
            </h1>
            <div className='flex flex-wrap justify-between '>
                <FeedbackCard/>
                <FeedbackCard/>
                <FeedbackCard/>
            </div>
        </div>
    )
}
