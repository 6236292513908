import Exchmarket from '../Images/Exchmarket.webp'
import {StarIcon} from "@heroicons/react/24/solid";
import Trust from "../Images/Trust.png"
import customer from '../Images/customer-review.png'
import satisfaction from '../Images/satisfaction.png'
import Offerbonus from '../Images/bonusOffer.png'
import Mobile from '../Images/PhoneApp.png'
import Payment from '../Images/payment-method.png'
import Exprience from '../Images/experience.png'
import betting from '../Images/betting.png'
import deposite from '../Images/deposit.png'
import withdrawal from '../Images/withdrawal.png'
import master from "../Images/master card.png";
import visa from "../Images/visa.png";
import paypal from "../Images/paypal.png";
import crypto from "../Images/cryptocurrency.png";
import GPay from "../Images/google-pay.png";
import Phonepe from "../Images/phonepe.png";
import BHIM from "../Images/BHIM.png";
import ClientSecurity from '../Images/ClientSecurity.png'
import SecureTransaction from '../Images/Securetransaction.png'
import CustomerSupport from '../Images/24-hours.png'
import bonuses from '../Images/bonuses.png'
import Interface from '../Images/UserEnterface.png'
import Diverse from '../Images/diversity.png'

import React from "react";


export const OnlineBettingSitetemp = (props) => {
    console.log(props.data)
    return (
        <div className={'md:mx-16 mx-2 '}>
            <div
                className={" md:h-64 md:p-9 p-6  my-2  rounded-lg text-white md:flex md:px-36 bg-[url('./Images/DarkBackground.png')]"}>
                <div className={'md:w-2/4'}>
                    <img src={props.data.header.sitelogo} alt="Exchmarket" className={'md:w-[400px] w-[14rem] m-auto'}/>
                    <span className={'flex mt-6 justify-center '}>
            <StarIcon className={'h-7  text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
            <StarIcon className={'h-7 '}/>
        </span>
                </div>
                <div className={'m-auto'}>
                    <h3 className={'mb-6 text-2xl font-bold '}>
                        {props.data.header.sitename}
                    </h3>
                    <h1 className={'mb-6 text-4xl font-bold '}>
                        {props.data.header.bonus}
                    </h1>
                    <button className="btn btn-outline btn-error btn-lg rounded-full">CLAIM BONUS</button>
                </div>
            </div>
            <div className={'grid  md:grid-flow-col  gap-4 my-6'}>
                <div className={'m-auto p-6 rounded-lg  bg-gray-100'}>
                    <img src={Trust} alt="Trust" className={'w-32 mb-4 m-auto'}/>
                    <p className={'text-4xl font-medium'}> 10 + Years of Trust</p>
                </div>
                <div className={'  m-auto p-6 rounded-lg  bg-gray-100'}>
                    <img src={customer} alt={'happy Customer'} className={'w-32 mb-4 m-auto'}/>
                    <p className={'text-4xl font-medium'}> 10L + Happy Customer</p>
                </div>
                <div className={'  m-auto p-6 rounded-lg  bg-gray-100'}>
                    <img src={satisfaction} alt="Trust" className={'w-32 mb-4 m-auto'}/>
                    <p className={'text-4xl font-medium'}> 100% Satisfaction</p>
                </div>
            </div>
            <div className="divider"></div>

            <h1 className={'my-6 md:text-4xl text-2xl font-bold text-start'}>{props.data.h1.heading}</h1>
            <p className={'my-6 text-start '}>
                {props.data.h1.paragraph}
            </p>
            <h1 className={'my-6 md:text-4xl text-2xl font-bold text-start'}>
                {props.data.h2.heading}
            </h1>
            <p className={'my-6 text-start '}>
                {props.data.h2.paragraph}
            </p>
            <div className={''}>
                <h2 className={"text-4xl font-medium my-9 m-auto"}>
                    Sky Exchange Score
                </h2>
                <div className={'grid md:grid-cols-4  md:grid-rows-2 grid-flow-row grid-col-1 gap-4 my-6'}>
                    <div className={'border rounded-lg p-6 bg-gray-100'}>
                        <img src={Offerbonus} alt={"offerbonus"} className={'w-32 mb-4 m-auto'}/>
                        <p className={'text-4xl font-medium'}> Bonus Offer</p>
                        <span className={'flex mt-6 justify-center'}>
            <StarIcon className={'h-7  text-red-600 '}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
            <StarIcon className={'h-7 '}/>
        </span>
                    </div>
                    <div className={'border rounded-lg p-6 bg-gray-100'}>
                        <img src={Mobile} alt={"offerbonus"} className={'w-24 mb-4 m-auto'}/>
                        <p className={'text-4xl font-medium'}> Mobile Experience</p>
                        <span className={'flex mt-6 justify-center'}>
            <StarIcon className={'h-7  text-red-600 '}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
            <StarIcon className={'h-7 '}/>
        </span>
                    </div>
                    <div className={'border rounded-lg p-6 bg-gray-100'}>
                        <img src={Payment} alt={"offerbonus"} className={'w-32 mb-4 m-auto'}/>
                        <p className={'text-4xl font-medium'}> Payment Options</p>
                        <span className={'flex mt-6 justify-center'}>
            <StarIcon className={'h-7  text-red-600 '}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
            <StarIcon className={'h-7 '}/>
        </span>
                    </div>
                    <div className={'border rounded-lg p-6 bg-gray-100'}>
                        <img src={Exprience} alt={"offerbonus"} className={'w-32 mb-4 m-auto'}/>
                        <p className={'text-4xl font-medium'}> Betting Exprience</p>
                        <span className={'flex mt-6 justify-center'}>
            <StarIcon className={'h-7  text-red-600 '}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
            <StarIcon className={'h-7 '}/>
        </span>
                    </div>
                    <div className={'border rounded-lg p-6 bg-gray-100'}>
                        <img src={customer} alt={"offerbonus"} className={'w-32 mb-4 m-auto'}/>
                        <p className={'text-4xl font-medium'}> Customer Support</p>
                        <span className={'flex mt-6 justify-center'}>
            <StarIcon className={'h-7  text-red-600 '}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
            <StarIcon className={'h-7 '}/>
        </span>
                    </div>
                    <div className={'border rounded-lg p-6 bg-gray-100'}>
                        <img src={betting} alt={"offerbonus"} className={'w-32 mb-4 m-auto'}/>
                        <p className={'text-4xl font-medium'}> Betting Variety</p>
                        <span className={'flex mt-6 justify-center'}>
            <StarIcon className={'h-7  text-red-600 '}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
            <StarIcon className={'h-7 '}/>
        </span>
                    </div>
                    <div className={'border rounded-lg p-6 bg-gray-100'}>
                        <img src={deposite} alt={"offerbonus"} className={'w-32 mb-4 m-auto'}/>
                        <p className={'text-4xl font-medium'}> Deposit</p>
                        <span className={'flex mt-6 justify-center'}>
            <StarIcon className={'h-7  text-red-600 '}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
            <StarIcon className={'h-7 '}/>
        </span>
                    </div>
                    <div className={'border rounded-lg p-6 bg-gray-100'}>
                        <img src={withdrawal} alt={"offerbonus"} className={'w-32 mb-4 m-auto'}/>
                        <p className={'text-4xl font-medium'}> Withdrawal</p>
                        <span className={'flex mt-6 justify-center'}>
            <StarIcon className={'h-7  text-red-600 '}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
              <StarIcon className={'h-7 text-red-600'}/>
            <StarIcon className={'h-7 '}/>
        </span>
                    </div>
                </div>
                <h1 className={'text-4xl font-medium my-9'}>Withdrawal & Deposit Methods</h1>
                <div className="flex flex-wrap justify-center">
                    <img src={master} alt='master card'
                         className="w-[10rem] md:mx-4 mx-2 border px-9 rounded-lg py-4   border-red-300 shadow mb-4 bg-gray-50"/>
                    <img src={visa} alt='visa card'
                         className="w-[10rem] md:mx-4 mx-2 border px-9 rounded-lg py-4 border-red-300 shadow mb-4 bg-gray-50"/>
                    <img src={paypal} alt=' paypal'
                         className="w-[10rem] md:mx-4 mx-2 border px-9 rounded-lg py-4 border-red-300 shadow mb-4 bg-gray-50"/>
                    <img src={crypto} alt='crypto'
                         className="w-[10rem] md:mx-4 mx-2 border px-9 rounded-lg py-4 border-red-300 shadow mb-4 bg-gray-50"/>
                    <img src={GPay} alt='crypto'
                         className="w-[10rem] md:mx-4 mx-2 border px-9 rounded-lg py-4 border-red-300 shadow mb-4 bg-gray-50"/>
                    <img src={Phonepe} alt='crypto'
                         className="w-[10rem] md:mx-4 mx-2 border px-9 rounded-lg py-4 border-red-300 shadow mb-4 bg-gray-50"/>
                    <img src={BHIM} alt='crypto'
                         className="w-[10rem] md:mx-4 mx-2 border px-9 rounded-lg py-4 border-red-300 shadow mb-4 bg-gray-50"/>
                </div>
            </div>
            <div>
                <h1 className={'text-4xl font-medium m-auto my-9'}>Exchmarket.net Bonus </h1>
                <div className='grid md:grid-cols-3 md:grid-rows-2 grid-col-2 grid-flow-row gap-3'>
<div className={ 'p-4 mx-3 rounded-full bg-red-200 border border-red-500 text-2xl'}><h1> 10% Up To <br/><span className={'text-3xl'}>20,000</span></h1> </div>
                    <div className={ 'p-4 mx-3 rounded-full bg-red-200 border border-red-500 text-2xl'}><h1> Maximum Bonus Amount <br/><span className={'text-3xl'}>20,000</span></h1> </div>
                    <div className={ 'p-4 mx-3 rounded-full bg-red-200 border border-red-500 text-2xl'}><h1> Turnover Amount <br/><span className={'text-3xl'}>5x Deposite</span></h1> </div>
                    <div className={ 'p-4 mx-3 rounded-full bg-red-200 border border-red-500 text-2xl'}><h1> Minimum Odds: <br/><span className={'text-3xl'}>2.0</span></h1> </div>
                    <div className={ 'p-4 mx-3 rounded-full bg-red-200 border border-red-500 text-2xl'}><h1> Minimum Deposite: <br/><span className={'text-3xl'}>500</span></h1> </div>
                    <div className={ 'p-4 mx-3 rounded-full bg-red-200 border border-red-500 text-2xl'}><h1>
                        Expiration Time<br/><span className={'text-3xl'}>30 Days</span></h1> </div>
                </div>
            </div>
            {
                props.data.h.map((items,index)=>{
                    return(
                        <>
                            <h1 className={'md:text-4xl text-2xl font-bold text-start  my-6'}>
                                {items.heading}
                            </h1>
                            <p className={'text-start   my-6'}>
                                {
                                    items.paragraph
                                }
                            </p>
                        </>
                    )
                })
            }
{/*            <h1 className={'my-6 md:text-4xl text-2xl font-bold text-start'}>*/}
{/*                Exchmarket.net: Market odds and margins*/}
{/*            </h1>*/}
{/*            <p className={"text-start   my-6 "}>*/}
{/*                Online betting sites rely heavily on odds and margins. To attract and keep customers, Exchmarket.net*/}
{/*                offers odds and margins that are competitive. However, they may differ based on the volume of bets*/}
{/*                placed, the event or market’s popularity, and the level of competition from other sportsbooks.*/}
{/*                <br/>*/}
{/*                The probabilities of an event occurring or not occurring, expressed in decimal or fractional form, are*/}
{/*                called odds. They decide how much you risk and how much money you can win from a bet. The event is less likely to occur, but the potential payout is higher, when the odds are higher. The event is more likely to occur, but the potential payout is lower, when the odds are lower.*/}
{/*<br/>*/}

{/*                The bookmaker’s commission, on the other hand, is represented by the margins. Bookmakers use edges to guarantee that they create a gain no matter what the result of a game. The margin is the difference between the betting company’s odds and the actual likelihood of the event occurring. The bettor benefits more from a lower margin because the bookmaker receives a smaller commission. In general, the Exchange Market wants to offer lower prices to get more people to buy from them.*/}
{/*<br/>*/}
{/*                The layout of the website is straightforward and uncluttered. At the top of each page is a navigation bar that makes it simple for visitors to navigate to the site’s sportsbook, casino, and live casino sections. The sportsbook itself is well-organized, with events organized by sport and league, making it simple for customers to locate the events they wish to wager on. Users can quickly and easily add their bets to their betting slips with just a few clicks, and the odds are displayed.*/}
{/*<br/>*/}
{/*                Real-time statistics and live streaming of some events are two additional features that the website provides to assist users in making informed decisions. The Exchmarket.net live betting page can also be accessed on smartphones and tablets because it is mobile-friendly.*/}
{/*<br/>*/}
{/*                The website’s overall usability appears to be intuitive and simple to use, with a variety of features that assist users in making informed betting decisions.*/}
{/*            </p>*/}
{/*            <h1 className={'md:text-4xl text-2xl font-bold text-start my-6'}> text-xl*/}
{/*                Exchmarket.net: Sports betting market*/}
{/*            </h1>*/}
{/*            <p className={'text-start   my-6'}>*/}
{/*                Any user can place bets on a variety of sports and other events offered by the bookmaker. The list includes popular Indian sports like horse racing, football, cricket, and tennis. The company may offer betting lines of varying widths depending on the event and level of user interest.*/}
{/*<br/>*/}
{/*                The sportsbook may offer a wider variety of betting lines and odds for popular sports and events, such as point spreads, money line bets, and proposition bets. The range of lines and odds may be smaller for less popular sports or events.*/}
{/*<br/>*/}
{/*                Apart from sports, Exchmarket.net also avails you of an opportunity to bet on events such as political elections and entertainment award shows. A very important note to the punters is that the sports mentioned above will be available to you depending on the location.*/}
{/*            </p>*/}
{/*            <h1 className={'md:text-4xl text-2xl font-bold text-start  my-6'}>*/}
{/*                Exchmarket.net: Live betting and streaming*/}
{/*            </h1>*/}
{/*            <p className={'text-start   my-6'}>*/}
{/*                Live betting on football, tennis, basketball, and other sports and events is available at Exchmarket.net. Users can place bets on an event while it is still in progress with live betting, which also known in the name of in-play is betting. The Bookmaker live betting platform has a live scoreboard, live statistics, and a variety of betting options that are designed to be easy to use. Clients can put down wagers on a scope of results, like the following objective or point, the last score, or the result of the game.*/}
{/*            </p>*/}
{/*            <h1 className={'md:text-4xl text-2xl font-bold text-start my-6'}>*/}
{/*                Exchmarket.net: Casino games library*/}
{/*            </h1>*/}
{/*            <p className={'text-start   my-6'}>*/}
{/*                Exchmarket.net provides a selection of video poker, table games, and slots from several reputable software developers, such as Supernowa, NetEnt, and Microgaming. With random number generators (RNGs) audited by independent testing organizations to guarantee fairness, they claim to provide transparent and fair gaming experiences.*/}
{/*                In addition, Exchmarket.net provides its customers with a VIP program for high rollers and a variety of bonuses and promotions, including welcome bonuses and free spins. They also have a website and Exchmarket app for playing games on the go, which are optimized for mobile devices. Explore a diverse and thrilling casino games library featuring Baccarat, Slots, Roulette, and Blackjack for an unforgettable gaming experience.*/}
{/*            </p>*/}
{/*            <h1 className={'md:text-4xl text-2xl font-bold text-start my-6'}>*/}
{/*                Exchmarket.net: Customer support*/}
{/*            </h1>*/}
{/*            <p className={'text-start  my-6'}>*/}
{/*                You can ask the experts at the bookmaker’s support team any questions you have about live bets, sports, markets, and other topics. On the website, there is a chat for online support where any player can get comprehensive guidance. Additionally, you can communicate with an Indian bookmaker via WhatsApp. You can schedule a phone call and meet with the site administration in person in an emergency.*/}
{/*            </p>*/}
{/*            <h1 className={'md:text-4xl text-2xl font-bold text-start my-6'}>*/}
{/*                Exchmarket.net: Our verdict*/}
{/*            </h1>*/}
{/*            <p className={'text-start   my-6'}>*/}
{/*                Any online trading or betting platform’s trustworthiness is determined by several factors, including regulatory compliance, user reviews, licensing, and security measures. If you’re thinking about using Exchmarket.net, it’s best to do extensive research and get professional advice to make sure the platform is reliable, reputable, and compliant with all applicable laws.*/}
{/*<br/>*/}
{/*                Exchmarket.net, in our opinion, is a notable licensed website that meets all of the requirements of a good bookmaker. To begin, the platform safeguards the financial and personal information of users through advanced security measures like encryption and two-factor authentication. The majority of the site’s reviews are favorable. Finally, the company also provides profitable odds on current events. As a result, this is an excellent casino and betting venue.*/}
{/*            </p>*/}
            <div className={'my-9'}>
                <h1 className={'text-4xl m-auto font-medium my-6'}> Why ChooseGamesWalla.net</h1>
                <div className={'grid md:grid-rows-2 md:grid-cols-3 gap-1'}>
                    <div className={'my-3 p-6 flex flex-wrap items-center bg-gray-200 rounded-lg'}>
                        <img src={ClientSecurity} alt='ClientSecurity' className={'w-28  m-auto'}/>
                        <h1 className={'text-3xl  ml-4 font-bold'}> Client Security and Privacy </h1>
                    </div>
                    <div className={'my-3 p-2  flex flex-wrap items-center bg-gray-200 rounded-lg'}>
                        <img src={SecureTransaction} alt='ClientSecurity' className={'w-28 m-auto '}/>
                        <h1 className={'text-3xl  ml-4 font-bold'}> Secure & Transparent Transactions</h1>
                    </div>
                    <div className={'my-3 p-2  flex flex-wrap items-center bg-gray-200 rounded-lg'}>
                        <img src={CustomerSupport} alt='ClientSecurity' className={'w-28  m-auto'}/>
                        <h1 className={'text-3xl  ml-4 font-bold'}> 24x7 Customer Support  </h1>
                    </div>
                    <div className={'my-3 p-2  flex flex-wrap items-center bg-gray-200 rounded-lg'}>
                        <img src={bonuses} alt='ClientSecurity' className={'w-28  m-auto'}/>
                        <h1 className={'text-3xl  ml-4 font-bold'}> Exclusive Promotions and Bonuses </h1>
                    </div>
                    <div className={'my-3 p-2  flex flex-wrap items-center bg-gray-200 rounded-lg'}>
                        <img src={Interface} alt='ClientSecurity' className={'w-28 m-auto '}/>
                        <h1 className={'text-3xl  ml-4 font-bold'}> User -Friendly Interface </h1>
                    </div>
                    <div className={'my-3 p-2  flex flex-wrap items-center bg-gray-200 rounded-lg'}>
                        <img src={Diverse} alt='ClientSecurity' className={'w-28  m-auto'}/>
                        <h1 className={'text-3xl  ml-4 font-bold'}>Premium Odds and Diverse Markets </h1>
                    </div>
                </div>
            </div>
            <div className={'flex flex-wrap justify-around bg-gray-100 p-6 rounded-lg'}>
                <button className={'btn btn-error md:btn-lg  mb-2 rounded-full'}> Get Instant Betting ID</button>
                <button className={'btn btn-error md:btn-lg mb-2  rounded-full'}> Contact on Whatsapp</button>
                <button className={'btn btn-error md:btn-lg  mb-2 rounded-full'}>Contact Us On Email</button>
            </div>
        </div>
    )
}
